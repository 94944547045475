import { Input } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { country_codes } from './countries';

export const CountryCodeSelector = ({
  width = 6,
  onChange,
  placeholder = 'Country Code',
  defaultValue,
  disabled = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const inputField = useRef();
  const [value, setValue] = useState(country_codes[defaultValue]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCountries, setFilteredCountries] = useState(
    Object.keys(country_codes)
  );

  useEffect(() => {
    if (defaultValue) {
      const temp = Object.values(country_codes)?.find(
        (each) => each.mobileCode == defaultValue
      );
      setValue(temp);
      setSearchTerm(temp?.mobileCode || '');
    }
  }, [defaultValue]);

  // Handle filtering based on search input
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = Object.keys(country_codes).filter((key) => {
      const mobileCode = country_codes[key]?.mobileCode || '';
      const countryName = country_codes[key]?.name || ''; // Assuming country name exists
      return (
        mobileCode.toLowerCase().includes(term) ||
        countryName.toLowerCase().includes(term)
      );
    });
    setFilteredCountries(filtered);
  };

  const handleBlur = () => {
    if (!searchTerm) {
      setValue(null); // Clear the selection if the input is empty
    }
    setTimeout(() => setIsVisible(false), 500); // Delay hiding the dropdown
  };

  return (
    <div
      className={`bg-white p-2 border-14 border-muted border d-flex col-lg-12 p-0 justify-content-start align-items-center border-14`}
    >
      {!!value ? (
        <img
          loading="lazy"
          src={`/images/flags/${value?.code?.toLowerCase()}.svg`}
          alt={value?.code}
          width="20"
          className="ml-1 p-0"
        />
      ) : null}{' '}
      <Input
        className={`ml-1 p-0`}
        style={{ border: 'none', boxShadow: 'none' }}
        ref={inputField}
        value={searchTerm}
        onChange={handleSearch}
        onFocus={(_) => setIsVisible(true)}
        onBlur={handleBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
      {isVisible ? (
        <div
          className="ant-select-dropdown mr-2"
          style={{
            minWidth: 150,
            width: 150,
            left: 0,
            top: 50,
            maxHeight: 256,
            overflowY: 'auto',
            overflowAnchor: 'none',
          }}
        >
          {filteredCountries?.map((key) => (
            <div
              onClick={(_) => {
                onChange(country_codes[key]?.mobileCode);
                setValue(country_codes[key]);
                setSearchTerm(country_codes[key]?.mobileCode);
                setIsVisible(false);
              }}
              role="button"
              key={key}
              className="ant-select-item ant-select-item-option custom-input"
            >
              <div className="ant-select-item-option-content">
                <img
                  loading="lazy"
                  src={`/images/flags/${country_codes[
                    key
                  ]?.code?.toLowerCase()}.svg`}
                  alt={key}
                  height="20"
                  width="20"
                />
                &nbsp;
                {country_codes[key]?.mobileCode}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
